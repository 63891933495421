
import {
  defineComponent,
  ref,
  reactive,
  onBeforeMount,
  computed,
  ComputedRef,
} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ITeacher } from "@/store/modules/TeacherModule";
import type { ElForm } from "element-plus";
type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  name: "generate-truck-schedule-modal",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const GenerateTruckScheduleModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const student = computed(() => {
      return store.state.StudentModule.selectedStudent;
    });

    const formRef = ref<FormInstance>();
    const form = reactive({
      teacherId: null,
      firstClassDate: null,
      studentId: null,
    });

    const rules = ref({
      teacherId: [
        {
          required: true,
          message: "Teacher is required",
          trigger: "change",
        },
      ],
      firstClassDate: [
        {
          type: "date",
          required: true,
          message: "First Class Date is required",
          trigger: "change",
        },
      ],
    });

    let teachers: ComputedRef<Array<ITeacher>> = computed(() => {
      return store.state.TeacherModule.teachers;
    });

    onBeforeMount(() => {
      if (store.state.TeacherModule.teachers.length === 0)
        store.dispatch(Actions.GET_TEACHERS);
    });

    const submit = (formEl: FormInstance | undefined) => {
      if (!formEl) {
        return;
      }
      formEl.validate((valid) => {
        if (valid) {
          loading.value = true;
          form.studentId = student.value.studentId;
          store
            .dispatch(Actions.GENERATE_TRUCK_SCHEDULE, form)
            .then((schedule) => {
              Swal.fire({
                text: "Schedule has been created successfully!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Continue",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Close modal after pressing confirm button
                const closeButton: any = document.querySelector(
                  "#kt_modal_generate_schedule_close"
                );
                if (closeButton) closeButton.click();

                var body: any = document.querySelector("#kt_body");
                body.style.overflow = "auto";
              });
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      form,
      teachers,
      rules,
      submit,
      formRef,
      loading,
      GenerateTruckScheduleModalRef,
    };
  },
});
