import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-4 col-sm-4 col-xl" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "card-body d-flex justify-content-center text-center flex-column p-8" }
const _hoisted_4 = { class: "text-gray-800 text-hover-primary d-flex flex-column" }
const _hoisted_5 = { class: "symbol symbol-60px mb-6" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "fs-5 fw-bolder mb-2" }
const _hoisted_8 = { class: "fs-7 fw-bold text-gray-400 mt-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: `${_ctx.linkComp}`,
      class: "card h-100"
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("a", null, [
              _createElementVNode("img", {
                src: `media/svg/files/pdf.svg`,
                alt: ""
              }, null, 8, _hoisted_6)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("a", null, _toDisplayString(_ctx.fileNameComp), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.createdAtComp), 1)
      ])
    ], 8, _hoisted_2)
  ]))
}