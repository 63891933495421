
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  onMounted,
} from "vue";
import File from "@/components/files/File.vue";
import SelectFileModal from "@/components/modals/forms/SelectFileModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { IDocument } from "@/store/modules/FileModule";

export default defineComponent({
  name: "documents",
  components: {
    File,
    SelectFileModal,
  },
  setup() {
    const store = useStore();

    onBeforeMount(() => {
      store.dispatch(Actions.GET_FILES);
    });

    //console.log(props.files)
    onMounted(() => {
      setCurrentPageBreadcrumbs("Documents", ["Pages", "Profile"]);
    });

    const files: ComputedRef<Array<IDocument>> = computed(() => {
      return store.state.FileModule.documents;
    });
    return {
      files,
    };
  },
});
