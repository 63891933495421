
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  onMounted,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import NewCardModal from "@/components/modals/forms/NewCardModal.vue";
import { useStore } from "vuex";
import UpdateStudentModal from "@/components/modals/forms/UpdateStudentModal.vue";
import { Actions } from "@/store/enums/StoreEnums";
import StudentTimeline from "./StudentTimeline.vue";
import { IStudent } from "@/store/modules/StudentModule";
import Documents from "@/views/crafted/pages/profile/Documents.vue";
import Evaluation from "@/views/crafted/pages/profile/Evaluation.vue";
import GenerateTruckScheduleModal from "@/components/modals/forms/GenerateTruckScheduleModal.vue";

export default defineComponent({
  name: "customer-details",
  components: {
    UpdateStudentModal,
    NewCardModal,
    StudentTimeline,
    Documents,
    Evaluation,
    GenerateTruckScheduleModal,
  },
  setup(props) {
    const store = useStore();

    onBeforeMount(() => {
      store.dispatch(Actions.GET_STUDENT);
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Student Details", ["Apps", "Students"]);
    });

    const student: ComputedRef<IStudent> = computed(() => {
      const student = store.state.StudentModule.selectedStudent;

      if (student.accountType === "TRUCK") {
        const truckPackages = store.state.StudentModule.truckPackages;
        const truckPackage = truckPackages.filter(
          (p) => p.templateId === student.truckerPackage
        );
        student.truckPackage = truckPackage.length > 0 ? truckPackage[0] : null;
      }
      return student;
    });

    return {
      student,
    };
  },
});
