
import { defineComponent, ref, getCurrentInstance } from "vue";
import { Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import { useResetForm } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "add-document-modal",
  components: {
    Form,
  },
  setup() {
    const store = useStore();
    const selectStudentModalRef = ref<null | HTMLElement>(null);
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const fileSelected = ref(null);

    const onPickFile = (event) => {
      console.log(event);
      fileSelected.value = event.target.files[0];
    };

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      store
        .dispatch(Actions.ADD_FILE, fileSelected)
        .then(() => {
          setTimeout(() => {
            if (submitButtonRef.value) {
              submitButtonRef.value.disabled = false;

              submitButtonRef.value?.removeAttribute("data-kt-indicator");
            }
            Swal.fire({
              text: "Form has been successfully submitted!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              hideModal(selectStudentModalRef.value);
              // location.reload();
            });
          }, 200);
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          }).then(() => {
            hideModal(selectStudentModalRef.value);
          });
        });
    };

    return {
      submit,
      submitButtonRef,
      selectStudentModalRef,
      fileSelected,
      onPickFile,
    };
  },
});
