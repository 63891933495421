
import { computed, ComputedRef, defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { IEvaluation } from "@/store/modules/EvaluationModule";

export default defineComponent({
  name: "add-evaluation-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const store = useStore();
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const evaluationModalRef = ref<null | HTMLElement>(null);

    const evaluationForm: ComputedRef<IEvaluation> = computed(() => {
      return {
        evaluationId: 0,
        evaluationType: "",
        evaluationDate: new Date(),
        driverLicense: "",
        score: 0,
        studentId: 0,
      };
    });

    const validationSchema = Yup.object().shape({
      score: Yup.number().max(100).required().label("score"),
    });

    const resetEvaluationForm = () => {
      evaluationForm.value.evaluationId = 0;
      evaluationForm.value.evaluationType = "";
      evaluationForm.value.evaluationDate = new Date();
      evaluationForm.value.driverLicense = "";
      evaluationForm.value.score = 0;
      evaluationForm.value.studentId = 0;
    };

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      console.log(evaluationForm.value);
      store.dispatch(Actions.ADD_EVALUATION, evaluationForm.value);

      setTimeout(() => {
        if (submitButtonRef.value) {
          submitButtonRef.value.disabled = false;

          submitButtonRef.value?.removeAttribute("data-kt-indicator");
        }

        Swal.fire({
          text: "Form has been successfully submitted!",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          hideModal(evaluationModalRef.value);
          resetEvaluationForm();
        });
      }, 200);
    };

    return {
      evaluationForm,
      validationSchema,
      submit,
      submitButtonRef,
      evaluationModalRef,
    };
  },
});
