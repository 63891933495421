
import { computed, ComputedRef, defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { IStudent } from "@/store/modules/StudentModule";

export default defineComponent({
  name: "update-student-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const store = useStore();
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const updateStudentModalRef = ref<null | HTMLElement>(null);

    const student: ComputedRef<IStudent> = computed(() => {
      return store.state.StudentModule.selectedStudent;
    });

    let studentToEdit: ComputedRef<IStudent> = computed(() => {
      return {
        studentId: student.value.studentId,
        fullName: student.value.fullName,
        email: student.value.email,
        phoneNumber: student.value.phoneNumber,
        fullAddress: student.value.fullAddress,
      };
    });

    const validationSchema = Yup.object().shape({
      fullName: Yup.string().required().label("full name"),
      email: Yup.string().required().label("email"),
      fullAddress: Yup.string().required().label("full address"),
      phoneNumber: Yup.string().required().label("phone number"),
    });

    const submit = () => {
      console.log(student);
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      store.dispatch(Actions.MODIFY_STUDENT, studentToEdit.value);
      setTimeout(() => {
        if (submitButtonRef.value) {
          submitButtonRef.value.disabled = false;

          submitButtonRef.value?.removeAttribute("data-kt-indicator");
        }

        Swal.fire({
          text: "Form has been successfully submitted!",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          hideModal(updateStudentModalRef.value);
        });
      }, 200);
    };

    return {
      student,
      studentToEdit,
      validationSchema,
      submit,
      submitButtonRef,
      updateStudentModalRef,
    };
  },
});
