
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
} from "vue";
import CreateEvaluationModal from "@/components/modals/forms/CreateEvaluationModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Card from "@/components/cards/EvaluationCard.vue";
import { IEvaluation } from "@/store/modules/EvaluationModule";

export default defineComponent({
  name: "evalutions",
  components: {
    Card,
    CreateEvaluationModal,
  },
  setup() {
    const store = useStore();

    onBeforeMount(() => {
      store.dispatch(Actions.GET_EVALUATIONS);
    });

    //console.log(props.files)
    onMounted(() => {
      setCurrentPageBreadcrumbs("Evalutions", ["Pages", "Profile"]);
    });

    const evaluations : ComputedRef<Array<IEvaluation>> = computed(() => {
      return store.state.EvaluationModule.evaluations;
    });
    return {
      evaluations,
    };
  },
});
