
import {computed, defineComponent, ref} from "vue";

export default defineComponent({
  name: "file",
  props: {
    fileName: String,
    createdAt: String,
    fileType: String,
    link: String,
  },
  components: {},
  methods: {},
  setup(props) {
    const linkComp = ref(props.link);
    const createdAtComp = computed(() => {
      return props.createdAt;
    });
    const fileTypeComp = computed(() => {
      return props.fileType;
    });

    const fileNameComp = computed(() => {
      return props.fileName;
    });

    return {
      linkComp,
      createdAtComp,
      fileTypeComp,
      fileNameComp,
    };
  },
});
