
import { computed, defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import { basePath } from "@/store/variables";
import { saveAs } from "file-saver";

export default defineComponent({
  name: "card-4",
  components: {},
  props: {
    score: Number,
    evaluationType: String,
    evaluationId: Number,
    evaluationDate: Date,
    driverLicense: String,
  },
  setup(props) {
    const scoreComp = computed(() => {
      return props.score;
    });
    const evaluationTypeComp = computed(() => {
      return props.evaluationType;
    });

    const evaluationIdComp = computed(() => {
      return props.evaluationId;
    });

    const evaluationDateComp = computed(() => {
      return props.evaluationDate;
    });

    const driverLicenseComp = computed(() => {
      return props.driverLicense;
    });

    const generateCertificate = async (id) => {
      ApiService.setHeader();
     console.log("TYYYYYYYYYYYYPPEEE:");
     console.log(evaluationTypeComp.value);
      if (evaluationTypeComp.value == "DRIVING_EXAM") {
        const response: any = await ApiService.vueInstance.axios
          .get(
            `${basePath}/students/${localStorage.getItem(
              "selectedStudentId"
            )}/certificate2`,
            { responseType: "arraybuffer" }
          )
          .then((response) => {
            const blob = new Blob([response.data], { type: "application/pdf" });
            saveAs(
              blob,
              `driving-certificate-${localStorage.getItem(
                "selectedStudentId"
              )}.pdf`
            );
          });
      } else {
        const response: any = await ApiService.vueInstance.axios
          .get(
            `${basePath}/students/${localStorage.getItem(
              "selectedStudentId"
            )}/certificate`,
            { responseType: "arraybuffer" }
          )
          .then((response) => {
            const blob = new Blob([response.data], { type: "application/pdf" });
            saveAs(
              blob,
              `theory-certificate-${localStorage.getItem(
                "selectedStudentId"
              )}.pdf`
            );
          });
      }
    };

    return {
      scoreComp,
      evaluationTypeComp,
      evaluationIdComp,
      evaluationDateComp,
      driverLicenseComp,
      generateCertificate,
    };
  },
});
