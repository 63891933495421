
import { computed, ComputedRef, defineComponent } from "vue";
import UpcomingClassItem from "@/views/apps/students/UpcomingClassItem.vue";
import store from "@/store";
import { IStudent } from "@/store/modules/StudentModule";
import { IScheduledClass } from "@/store/modules/IScheduleClass";

export default defineComponent({
  name: "student-timeline",
  components: {
    UpcomingClassItem,
  },
  setup() {
    var dateOptions: any = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    var today = new Date().toLocaleDateString("en-US", dateOptions);

    const scheduledClasses: ComputedRef<Array<IScheduledClass>> = computed(
      () => {
        return store.state.StudentModule.selectedStudent.scheduledClasses;
      }
    );
    const student: ComputedRef<IStudent> = computed(() => {
      return store.state.StudentModule.selectedStudent;
    });

    const todayScheduledClasses = computed(() => {
      if (scheduledClasses.value) {
        const classes = scheduledClasses.value.filter((scheduledClass) => {
          const date = new Date(scheduledClass.startDateTime);
          const match =
            date.getTime() > new Date().getTime() &&
            date.getFullYear() === new Date().getFullYear() &&
            date.getMonth() === new Date().getMonth() &&
            date.getDate() === new Date().getDate();
          return match;
        });
        return sortClassesByDate(classes);
      } else {
        return [];
      }
    });

    const weekScheduledClasses = computed(() => {
      if (scheduledClasses.value) {
        const classes = scheduledClasses.value.filter((scheduledClass) => {
          let futureDate = new Date();
          futureDate.setDate(futureDate.getDate() + 7);
          const date = new Date(scheduledClass.startDateTime);
          const daysDiff = (futureDate.getTime() - date.getTime()) / 86400000;
          const match =
            date.getTime() > new Date().getTime() &&
            daysDiff < 7 &&
            daysDiff > 0;
          return match;
        });
        return sortClassesByDate(classes);
      } else {
        return [];
      }
    });

    const monthScheduledClasses = computed(() => {
      if (scheduledClasses.value) {
        const classes = scheduledClasses.value.filter((scheduledClass) => {
          const date = new Date(scheduledClass.startDateTime);
          const match =
            date.getTime() > new Date().getTime() &&
            date.getFullYear() === new Date().getFullYear() &&
            date.getMonth() === new Date().getMonth();
          return match;
        });
        return sortClassesByDate(classes);
      } else {
        return [];
      }
    });

    const yearScheduledClasses = computed(() => {
      if (scheduledClasses.value) {
        const classes = scheduledClasses.value.filter((scheduledClass) => {
          const date = new Date(scheduledClass.startDateTime);
          const match =
            date.getTime() > new Date().getTime() &&
            date.getFullYear() === new Date().getFullYear();
          return match;
        });
        return sortClassesByDate(classes);
      } else {
        return [];
      }
    });

    const sortClassesByDate = (classes: Array<IScheduledClass>) => {
      const sortedClasses = classes.sort((a, b) => {
        return (
          new Date(a.startDateTime).getTime() -
          new Date(b.startDateTime).getTime()
        );
      });

      return sortedClasses;
    };

    return {
      today,
      student,
      todayScheduledClasses,
      weekScheduledClasses,
      monthScheduledClasses,
      yearScheduledClasses,
    };
  },
});
